@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.quoteCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  @include spacingAround(2);


  .quote {
    @include FontSecondaryRegularItalic();
    color: var(--secondary-color);
    font-size: var(--font-size-headline-h3);
    line-height: 28px;
    @include spacingBottom(3)
  }

  .author {
    color: var(--black);
    @include FontPrimaryBold();
    font-size: var(--font-size-text);
    @include spacingBottom(1);
  }

  .company {
    @extend .author;
    @include spacingBottom(0);
  }

  .role {
    @include FontPrimaryRegular();
    color: var(--black);
    position: relative;

    p {
      height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
