@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.searchButton {
  width: 100%;
  border-radius: var(--global-search-button-radius-mobile);
  padding: 0;
  background-color: var(--primary-color);
  border: none;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.ghost {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
    }

    .btnIcon {
      svg {
        fill: var(--primary-color);
      }
    }
  }

  &:hover {
    background-color: var(--primary-hover-color);
  }

  @include spacingTopBottom(2);

  .btnIcon {
    display: none;
  }

  .btnText {
    @include FontSecondaryRegular;
    color: var(--white);
    text-transform: uppercase;
  }

  @media all and (min-width: $laptop) {
    border-radius: var(--global-search-button-radius);
    width: var(--global-button-side-length);
    height: var(--global-button-side-length);
    padding: 0;

    .btnIcon {
      display: block;
      height: calc(var(--global-button-side-length) / 2);

      svg, span {
        height: calc(var(--global-button-side-length) / 2) !important;
        width: auto;
        font-size: calc(var(--global-button-side-length) / 2);
        line-height: calc(var(--global-button-side-length) / 2);
      }
    }

    .btnText {
      display: none;
    }
  }
}
