@use '@digando/common-ui/scss/variables' as *;
@use '../../../layout/container.scss' as *;

.globalSearchBarContainer {
  line-height: 0;
  position:relative;

  @media all and (min-width: $tablet) {
    padding: var(--global-search-bar-padding-top-down) var(--global-search-bar-padding-right) var(--global-search-bar-padding-top-down) var(--global-search-bar-padding-left);
    margin-top: spacingCalculator(2);

    &.noSpacing {
      margin-top: 0;
    }
  }

  @media all and (min-width: $laptop) {
    margin-top: spacingCalculator(2);
    display: block;
    max-width: 100%;

    background-color: var(--global-search-bar-background);
    border-radius: var(--global-search-bar-radius);

    &.noSpacing {
      margin-top: 0;
    }
  }

  .formWrapper {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: spacingCalculator(4);

    @media all and (min-width: $laptop) {
      flex-direction: row;
      justify-content: space-between;
      gap: spacingCalculator(1);
    }

    .removeAfter label::after {
      content: none !important;
    }
  }

  .fromToFieldWrapper {
    width: 100%;

    @media all and (min-width: $laptop) {
      width: 34.35%;
    }
  }

  .searchButtonWrapper {
    width: 100%;

    @media all and (min-width: $laptop) {
      width: auto;
    }
  }
}

.pageHeaderDesktopForm {
  display: none;

  @media all and (min-width: $laptop) {
    display: flex;
  }
}

.pageHeaderMobileForm {
  @media all and (min-width: $laptop) {
    display: none
  }
}

.pageHeaderGlobalSearchBarContainer {
  justify-self: center;
  width: 100%;

  @media all and (min-width: $laptop) {
    width: 100% !important;
    background-color: var(--white);
    border-radius: var(--global-search-bar-header-border-radius-mobile);
    height: 56px;

    display: flex;
    align-items: center;
  }
}

.pageHeaderDesktopFormWrapper {
  max-width: 920px;
  display: inline-block;
  @include spacingLeftRight(2);
  background-color: transparent;

  div {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media all and (min-width: $laptop) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    display: flex;
    align-items: center;
  }
}

.suggestionWrapper {
  @extend .contentContainerRegular;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  display: none;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: spacingCalculator(0) spacingCalculator(2) spacingCalculator(3) spacingCalculator(2);
  transition: opacity 0.3s ease;
  margin: 0;

  border-radius: 0;
  background-color: var(--background-light);
  overflow-y: hidden;

  @media all and (min-width: $laptop) {
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: auto;
    left: 0;
    height: auto;
    background-color: var(--white);
    border-radius: var(--global-search-suggestions-border-radius);
    margin-top: spacingCalculator(1.5);
    @include spacingAround(2);
  }

  * {
    color: var(--black);
  }

  &.isOpen {
    opacity: 1;
    pointer-events: auto;
  }

  &.isMainSearchVisible {
    display: block;
  }
}

.suggestionWrapperHeader {
  @extend .suggestionWrapper;

  position: absolute;
  display: block;
  max-width: min(90vw, 940px);
  left: 50%;
  transform: translateX(-50%);
  top: 75px;
  height: 0;

  @media all and (min-width: $desktop) {
    min-width: 920px;
  }

  &.isOpen {
    height: auto;
  }
}

.pageHeaderSearchDropdown {
  background-color: var(--secondary-color);
  position: absolute;
  top: 97px;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0);
  @include spacingAround(4);
  display: flex;
  flex-direction: column;
  gap: spacingCalculator(4);

  @media all and (min-width: $tablet) {
    top: 92px;
    height: 340px;
  }
}
