@use '../../../../../../libs/ui/common-ui/src/lib/styles/variables.module.scss' as *;
@use '@digando/common-ui/scss/fonts' as *;

.categoryCardItem {
  --category-card-item-pointer-size: 8px;
  --category-card-item-pointer-display: block;

  display: flex;
  align-items: center;
  cursor: pointer;
  @include spacingTopBottom(1);
  gap: spacingCalculator(1);

  @media all and (min-width: $tablet) {
    @include spacingTopBottom(0.5);
  }

  .titleWrapper {
    @include FontPrimaryRegular;
    font-size: var(--font-size-text);
    line-height: 22px;
  }

  .item {
    display: var(--category-card-item-pointer-display);
    width: var(--category-card-item-pointer-size);
    min-width: 8px;
    height: var(--category-card-item-pointer-size);
    border-radius: 1px;
    background-color: var(--primary-color);
  }
}
