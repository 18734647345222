@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.zoneTitle {
  @include spacingBottom(6);
  grid-column: auto / span 12;

  &.smallSpacing {
    @include spacingBottom(2);
  }

  &.noSpacing {
    @include spacingBottom(0);
  }

  @media all and (min-width: $tablet) {
    @include spacingBottom(4);

    &.smallSpacing {
      @include spacingBottom(1);
    }

    &.noSpacing {
      @include spacingBottom(0);
    }
  }

  .looksAsH1,
  .looksAsH2 {
    @include FontSecondarySemiBold;
    padding: 0;
    font-size: var(--font-size-headline-h2-mobile);
    line-height: var(--line-height-headline-h2-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h2);
      line-height: var(--line-height-headline-h2);
    }
  }

  .looksAsH3 {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-headline-h3-mobile);
    line-height: var(--line-height-headline-h3-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h3);
      line-height: var(--line-height-headline-h3);
    }
  }

  .looksAsH4,
  .looksAsH5,
  .looksAsH6 {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-headline-h4-mobile);
    line-height: var(--line-height-headline-h4-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h4);
      line-height: var(--line-height-headline-h4);
    }
  }
}
