@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.DigandoAdvantagesContainer {
  color: var(--secondary-contrast-font-color);
  display: flex;
  gap: spacingCalculator(1);
  justify-content: flex-start;

  &.centered {
    justify-content: center;
  }

  p {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-text-small);
    line-height: 22px;
    margin-bottom: 0;
    text-align: left;

    @media all and (min-width: $tablet) {
      @include FontSecondarySemiBold;
      font-size: var(--font-size-text-small);
      line-height: 16.8px;
    }

    @media all and (min-width: $laptop) {
      @include FontSecondaryRegular;
      font-size: var(--font-size-text-small-title);
      line-height: 20px;
    }
  }
}
