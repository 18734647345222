@import '@digando/common-ui/scss/variables';

.desktopDateFieldWrapper {
  display: none;

  @media all and (min-width: $laptop) {
    display: inline-block;
    width: 50%;
    font-size: 0;
    line-height: 0;
  }
}

.mobileDateFieldWrapper {
  position: relative;
  display: inline-block;
  width: calc(50% - 4px);


  @media all and (min-width: $laptop) {
    display: none;
  }
}

.fromDateField {
  margin-right: spacingCalculator(1);
}

.toDateField {
  margin-left: spacingCalculator(1);
}

.calendarIcon {
  right: 10px;
  top: 30px;
  transform: translateY(-50%);
  position: absolute;
  width: 26px;
  height: 26px;
  pointer-events: none;
  color: var(--primary-color);
  background-color: var(--white);

  * {
    pointer-events: none;
  }
}
