@use '@digando/common-ui/scss/variables' as *;

.newsletter {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  align-items: start;

  .successMessage {
    grid-column: auto / span 2;
    color: var(--white);
  }

  .inputWrapper {
    grid-column: auto / span 2;

    @media all and (min-width: $tablet) {
      grid-column: auto / span 1;
    }
  }

  .buttonWrapper {
    grid-column: auto / span 2;

    .buttonText {
      text-transform: uppercase;
    }

    @media all and (min-width: $tablet) {
      grid-column: auto / span 1;
    }
  }
}

.formContainer {
  width: 100%;
  position: relative;
}
