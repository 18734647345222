@import '@digando/common-ui/scss/variables';

.container {
  width: 100%;

  @media all and (min-width: $laptop) {
    width: 43%;
    flex-grow: 2;
  }
}
