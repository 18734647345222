@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.productPrice {
  --product-price-detail-label-color: var(--font-color-light);

  display: grid;

  .priceInfoColumn {
    display: none;
    @include FontPrimaryRegular;
    font-size: var(--font-size-text-small);
    line-height: 20px;
    color: var(--product-price-detail-label-color);
    justify-content: flex-end;
    align-items: end;

    @media all and (min-width: $tablet) {
      display: flex;
    }
  }

  .detailsLabel {
    text-decoration: underline;
    padding-right: 3px;
    cursor: default;
  }
}
