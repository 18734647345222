.gridLayout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

  margin-left: auto;
  margin-right: auto;
}

.ContentContainerMax {
  @extend .gridLayout;

  width: 100vw;
  max-width: 1424px;

  @media all and (max-width: 1600px) {
  width: 89vw;
  max-width: none;
}

  &.carouselFix {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  &.smallWidth {
    max-width: 1184px;

    @media all and (max-width: 1360px) {
      width: 89vw;
      max-width: none;
    }
  }

  @media all and (max-width: 900px) {
    width:95vw;
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
  }

  @media all and (max-width: 767px) {
    width: 95vw;
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);

    &.noMobileSpacing {
      width: 100vw;
    }
  }
}

.contentContainerRegular {
  @extend .gridLayout;

  width: 100vw;
  max-width: 1050px;

  @media all and (max-width: 1204px) {
    width: 90vw;
    max-width: none;
    grid-gap: 3vw;

    &.noMobileSpacing {
      width: 100vw;
    }
  }

  @media all and (max-width: 900px) {
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
    max-width: 95vw;

    &.noMobileSpacing {
      max-width: 100vw;
    }
  }
}
